var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "faq" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("FAQ")]),
    _c(
      "dl",
      { staticClass: "faq-list" },
      [
        _vm._l(_vm.faqList, function(faq, index) {
          return [
            _c(
              "dt",
              {
                staticClass: "faq-list__title",
                class: { "is-active": faq.active },
                on: {
                  click: function($event) {
                    return _vm.toggleFaqItem(index)
                  }
                }
              },
              [_vm._v(" " + _vm._s(faq.title) + " ")]
            ),
            _c(
              "SlideUpDown",
              {
                staticClass: "faq-list__body",
                class: { "is-active": faq.active },
                attrs: { tag: "dd", active: faq.active, duration: _vm.duration }
              },
              [_vm._v(" " + _vm._s(faq.body) + " ")]
            )
          ]
        })
      ],
      2
    ),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v("Using the "),
      _c(
        "a",
        {
          staticClass: "faq__link",
          attrs: {
            href: "https://github.com/danieldiekmeier/vue-slide-up-down/"
          }
        },
        [_vm._v("vue-slide-up-down component")]
      ),
      _vm._v(".")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }